// Set of utilities related to formatting strings for specifications.

export function formatSpecSection(section) {
  // If the section is special string that designates a section of the library (e.g., the Python library)
  // return just "Library".
  // If the section is a special string that designats a wiki page (e.g., the Scatch Wiki),
  // return "".
  // Otherwise the section probably is a number (of a chapter), return it as it is.
  return section.startsWith("lib") ? "Library" : 
    section.startsWith("wiki-") ? "" : section;
}

export function getSpecSectionTitle(specsMdx, spec, section) {
  const node = specsMdx.nodes
    .filter(node => node.parent.name===spec)
    .find(node => node.frontmatter.sections.find(s => s.section === section));
  if (node) {
    const s = node.frontmatter.sections.find(s => s.section === section);
    return s.title;
  } else {
    return null;
  }
}