import React from "react"
import {
  Container,
  Header,
  List,
  Icon,
  Breadcrumb,
  Message,
} from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/progmiscon-logo-64.png"
import { formatSpecSection } from "../components/spec_utils"

// pageContext comes from createPage, data comes from the below GraphQL page query
const SpecificationSectionPageTemplate = ({ pageContext, data }) => {
  const { sectionId } = pageContext
  const { nodes } = data.allMdx
  const node = nodes[0]
  const section = node.frontmatter.sections.filter(
    section => section.section === sectionId
  )[0]
  return (
    <Layout>
      <SEO
        title={`${section.section} ${section.title} - ${node.parent.name}`}
      />
      <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link as={Link} to="/specs">
            Languages
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section>{node.frontmatter.pl}</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link as={Link} to={`/specs/${node.parent.name}`}>
            {node.parent.name}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{section.title}</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1" dividing>
          <Icon name="code" />
          <Header.Content>
            <Header.Subheader>
              {node.frontmatter.name}, Version {node.frontmatter.version}
            </Header.Subheader>
            {formatSpecSection(section.section)}. {section.title}
          </Header.Content>
        </Header>

        {node.frontmatter.baseUrl ? (
          <Message icon info>
            <Icon name="certificate" />
            <Message.Content>
              <Message.Header>
                Read this Section of the Authoritative Specification
              </Message.Header>
              <p>
                <a
                  href={`${node.frontmatter.baseUrl}${section.url}`}
                >{`${node.frontmatter.baseUrl}${section.url}`}</a>
              </p>
            </Message.Content>
          </Message>
        ) : null}

        <p>{data.mcs.totalCount} Documented Misconceptions</p>

        <List selection verticalAlign="middle">
          {data.mcs.edges.map(({ node }) => (
            <List.Item as={Link} to={node.fields.slug} key={node.fields.slug}>
              <img
                className="ui image"
                alt="Misconception"
                width="20"
                src={logo}
              />
              <List.Content>
                <List.Header>
                  {/* color this like a link, without producing a nested a element */}
                  <span style={{ color: "#4183c4" }}>{node.fields.name}</span>
                </List.Header>
                {node.frontmatter.shortDescription}
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Container>
    </Layout>
  )
}

export default SpecificationSectionPageTemplate

export const query = graphql`
  query($glob: String!, $specificationId: String!, $sectionId: String!) {
    allMdx(filter: { fileAbsolutePath: { glob: $glob } }) {
      nodes {
        frontmatter {
          name
          version
          pl
          home
          baseUrl
          sections {
            section
            title
            url
          }
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
    mcs: allMdx(
      filter: {
        frontmatter: {
          spec: {
            elemMatch: {
              id: { eq: $specificationId }
              sections: { eq: $sectionId }
            }
          }
        }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            name
          }
          frontmatter {
            shortDescription
            text {
              id
              sections
            }
          }
        }
      }
    }
  }
`
